/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const BonLookBenefits = ({ lang }) => {
  const copyStrings = {
    en: {
      heading: 'What do I get with a BonLook Trendsetter membership?',
      benefit1: 'Free standard shipping',
      benefit2: 'Free glasses',
      benefit3: 'Exclusive events',
    },
    fr: {
      heading: 'Quels sont les avantages d’adhérer au programme Trendsetter de BonLook?',
      benefit1: 'Livraison standard gratuite',
      benefit2: 'Monture gratuite',
      benefit3: 'Événements exclusifs',
    },
  };

  const settings = {
    dots: true,
    infinite: false,
    arrows: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 920,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 620,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  useEffect(() => {
    // on mount
  }, []);

  return (
    <div className="storefront-benefits">
      {/* <div className="storefront-benefits__overlay"></div> */}
      <div className="storefront-benefits__inner">
        <div className="storefront-benefits__title">{copyStrings[lang].heading}</div>
        <div className="custom-benefits__row">
          <Slider {...settings}>
            <div>
              <div className="storefront-benefits__item">
                <div className="storefront-benefits__item--title">{copyStrings[lang].benefit1}</div>
                <div
                    className="storefront-benefits__item--image"
                    style={{
                      backgroundImage: 'url(https://assets.heyethos.com/bonlook/landing/thumb-shipping2.jpg)',
                    }}>
                </div>
              </div>
            </div>
            <div>
              <div className="storefront-benefits__item">
                <div className="storefront-benefits__item--title">{copyStrings[lang].benefit2}</div>
                <div
                    className="storefront-benefits__item--image"
                    style={{
                      backgroundImage: 'url(https://assets.heyethos.com/bonlook/landing/thumb-early-access2.jpg)',
                    }}>
                </div>
              </div>
            </div>
            <div>
              <div className="storefront-benefits__item">
                <div className="storefront-benefits__item--title">{copyStrings[lang].benefit3}</div>
                <div
                    className="storefront-benefits__item--image"
                    style={{
                      backgroundImage: 'url(https://assets.heyethos.com/bonlook/landing/thumb-events2.jpg)',
                    }}>
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </div>
    </div>
  );
};
export default BonLookBenefits;
