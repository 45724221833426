import Ajax from './Ajax';

/**
* Utility helper for Account functions
*/

class Contests {
  constructor() {
    this.apiUrl = process.env.SERVER_URL;
  }

  getContest = (token, contestId) => Ajax.get(this.apiUrl + 'Contest/byId?contestId=' + contestId, token)
    .then((data) => data)
    .catch((error) => {
      // that.clear();
      throw error;
    })

  enterContest(token, payload) {
    return Ajax.post(this.apiUrl + 'Contest/enter', payload, { Authorization: token ? 'Bearer ' + token : '' })
      .then((data) => data)
      .catch((error) => {
        // that.clear();
        throw error;
      });
  }
}

export default new Contests();
