import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import Utility from '../../managers/Utility';
import Account from '../../managers/Account';
import Throbber from '../throbber';
import SimpleModal from '../modal/modal';

function CodeRevealer({ utility }) {
  const [showCode, setShowCode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [voucherCode, setVoucherCode] = useState('');

  const revealCode = (e) => {
    e.preventDefault();
    if (showCode) {
      setShowCode(false);
    } else {
      setShowCode(true);
    }
  };

  const copyPCToClipboard = (utility, url, e) => {
    e.preventDefault();
    navigator.clipboard.writeText(utility.promoCode);
    toast.success('Promo code has been copied to clipboard.');
    // setTimeout(() => {
    //   window.open(url, '_blank');
    // }, 2000);
  };

  const copyToClipboard = (voucher, url, e) => {
    e.preventDefault();
    navigator.clipboard.writeText(voucherCode);
    toast.success('Promo code has been copied to clipboard.');
    // setTimeout(() => {
    //   window.open(url, '_blank');
    // }, 2000);
  };

  const claimVoucherCode = async (utilityId, e) => {
    e.preventDefault();
    setIsLoading(true);
    const response = await Utility.claimVoucher(utilityId, Account.getToken());
    if (response && response.success) {
      toast.success('Promo code has been claimed.');
      setVoucherCode(response.voucherCode);
      setShowCode(true);
    } else {
      toast.error(response.message ? response.message : 'Failed to claim promo code. Our team has been notified. Please try again later.');
    }
    setIsLoading(false);
  };

  return (
    <div>
      {isLoading && (
        <SimpleModal isOpen={isLoading}>
          <Throbber throbberText="Fetching your voucher code. One second..." />
        </SimpleModal>
      )}
      {utility.promoCode && (
      <>
        {!showCode
        && <a href="#" onClick={(e) => revealCode(e)} className="button-collection" target="_blank" rel="noreferrer">{utility.linkLabel || 'Claim the offer'}</a>}
          {showCode
        && (
        <div className="utility-item__voucher-slot">
          <input className="utility-item__voucher-input" type="text" readOnly value={utility.promoCode} />
          <button
              type="button"
              className="utility-item__voucher-button"
              onClick={(e) => copyPCToClipboard(utility, utility.linkUrl, e)}>
            {process.env.CUSTOM_COPY_ICON
              ? <img src={process.env.CUSTOM_COPY_ICON} width="14" alt="copy" />
              : <img src="https://assets.heyethos.com/ethos/ui/icon-copy.svg" width="14" alt="copy" />}
          </button>
        </div>
        )}
      </>
      )}
      {!utility.promoCode && (
        <div>
          {!showCode
          && <a href="#" onClick={(e) => claimVoucherCode(utility.utilityId, e)} className="button-collection" target="_blank" rel="noreferrer">Claim the offer</a>}
          <div className="utility-item__voucher-slot">
            {showCode
          && (
          <>
            <input className="utility-item__voucher-input" type="text" readOnly value={voucherCode} />
            <button
              type="button"
              className="utility-item__voucher-button"
              onClick={(e) => copyToClipboard(utility, utility.linkUrl, e)}>
              {process.env.CUSTOM_COPY_ICON
                ? <img src={process.env.CUSTOM_COPY_ICON} width="14" alt="copy" />
                : <img src="https://assets.heyethos.com/ethos/ui/icon-copy.svg" width="14" alt="copy" />}
            </button>
          </>
          )}
          </div>
        </div>
      )}
    </div>
  );
}

export default CodeRevealer;
