/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';

function BonLookFAQ({ noHeader, lang }) {
  const [questions, setQuestions] = useState([]);
  const toggleAnswer = (id, e) => {
    e.preventDefault();
    const cloneQuestions = [...questions];
    cloneQuestions.forEach((faq) => {
      if (faq.id === id) {
        faq.isOpen = !faq.isOpen;
      }
    });
    setQuestions(cloneQuestions);
  };

  const faq = {
    en: [
      {
        id: 1,
        title: 'What is the BonLook Trendsetters Program?',
        answer: 'The Trendsetters Rewards Program is our free membership program that rewards you along your style journey. Get more shopping benefits, access and ways to level up. Find the Terms and Conditions is our free membership program that rewards you along your style journey. Get more shopping benefits, access and ways to level up. Find the Terms and Conditions, <a href="https://www.heyethos.com/terms-of-service" target="_blank">here</a>.',
      },
      {
        id: 2,
        title: 'How do I join?',
        answer: 'Simple and easy. Enter your email address at the top of the page and you’re ready to go!',
      },
      {
        id: 3,
        title: 'Where can I track my progress?',
        answer: 'You can track your progress by logging into your Trendsetter account and going to your benefits page. When you level up to a new tier, you will see it in your benefits page.',
      },
      {
        id: 4,
        title: 'How is my membership status determined?',
        answer: 'Your Trendsetter tier is determined by how many pairs of frames you purchase and how much you spend each calendar year. You can make these purchases at bonlook.ca or at one of our retail locations. Scroll up to see the minimum annual spend for each tier in our benefits chart.',
      },
      {
        id: 5,
        title: 'How does earning work with the BonLook Trendsetter Program?',
        answer: 'It’s easy: the more you shop, the closer you get to leveling up to a new tier and accessing more perks.',
      },
      {
        id: 6,
        title: 'What happens to my rewards if I return something?',
        answer: 'When you return an item, that amount will be deducted from your yearly spending total.',
      },
      {
        id: 7,
        title: 'How do I add my birthday to my account to redeem my birthday gift?',
        answer: 'Go on <a href="https://www.bonlook.ca/" target="_blank">bonlook.ca</a>, sign into “My Account.” Into your profile, click on “Edit your information” and add your date of birth. Your discount will be available on the benefits page in your birthday month. ',
      },
      {
        id: 8,
        title: 'Terms and conditions',
        answer: '<a href="https://www.bonlook.ca/pages/trendsetter-program-terms">Click here</a> to see the terms and conditions of all offers.',
      },
    ],
    fr: [
      {
        id: 1,
        title: 'Qu’est-ce que le programme Trendsetter de BonLook?',
        answer: 'Trendsetter est un programme gratuit qui vous récompense tout au long de votre évolution de style. Profitez de plus d’avantages, d’accès et de façons de passer au prochain niveau. Apprenez-en davantage sur les détails complets du programme, en plus des conditions générales, <a href="#" target="_blank">ici.</a>',
      },
      {
        id: 2,
        title: 'Comment m’inscrire?',
        answer: 'C’est simple et facile. Entrez votre adresse courriel dans le haut de la page et le tour est joué!',
      },
      {
        id: 3,
        title: 'Où puis-je suivre mon progrès?',
        answer: 'Vous pouvez suivre votre progrès en vous connectant à votre compte Trendsetter et en allant sur votre page d’avantages. Lorsque vous passez à un nouveau niveau, vous le verrez sur votre page d’avantages.',
      },
      {
        id: 4,
        title: 'Comment mon statut de membre est-il déterminé?',
        answer: 'Votre niveau Trendsetter est déterminé selon le nombre de montures achetées et le montant dépensé au cours de chaque année civile. Ces achats peuvent être faits sur bonlook.ca ou dans l’une de nos succursales. Défilez vers le haut pour connaître le montant minimal à dépenser par niveau en consultant notre tableau d’avantages.',
      },
      {
        id: 5,
        title: 'Comment progresser au sein du programme Trendsetter de BonLook?',
        answer: 'C’est facile : plus vous magasinerez, plus vous vous approcherez du niveau supérieur, qui vous donnera accès à encore plus d’avantages.',
      },
      {
        id: 6,
        title: 'Qu’en est-il de mes récompenses si j’effectue un retour de marchandise?',
        answer: 'Si vous nous retournez un article, le montant sera déduit du total annuel dépensé.',
      },
      {
        id: 7,
        title: 'Comment faire pour ajouter ma date d’anniversaire à mon profil afin de réclamer mon cadeau d’anniversaire?',
        answer: 'Rendez-vous sur <a href="https://www.bonlook.ca/" target="_blank">bonlook.ca</a> et connectez-vous à « Mon compte ». Dans votre profil, cliquez sur « Modifier vos informations » et ajoutez votre date d’anniversaire. Votre rabais sera disponible tout le mois de votre anniversaire sur la page des avantages.',
      },
      {
        id: 8,
        title: 'Terms and conditions',
        answer: '<a href="https://www.bonlook.ca">Click here</a> to see the terms and conditions of all offers.',
      },
    ],
  };

  useEffect(() => {
    setQuestions(faq[lang]);
  }, [lang]);

  return (
    <div className="asset-faq">
      {!noHeader
      && <h2 className="digital-asset__subheader -center">FAQ</h2>}
      {questions.map((faq) => (
        <div
          key={faq.id}
          className="asset-faq__item">
          {faq.isOpen
            ? (
              <a
                className="asset-faq__toggle"
                href="#"
                onClick={(e) => toggleAnswer(faq.id, e)}>
                -
              </a>
            )
            : (
              <a
                className="asset-faq__toggle"
                href="#"
                onClick={(e) => toggleAnswer(faq.id, e)}>
                +
              </a>
            )}
          <div
            className="asset-faq__item--question"
            onClick={(e) => toggleAnswer(faq.id, e)}>
            {faq.title}
          </div>
          <div
            className={faq.isOpen ? 'asset-faq__item--answer -open' : 'asset-faq__item--answer'}
            dangerouslySetInnerHTML={{ __html: faq.answer }}>
          </div>
        </div>
      ))}
      
    </div>
  );
}

export default BonLookFAQ;
