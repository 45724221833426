import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function AllureAboutEdrop() {
  // const [questions, setQuestions] = useState([]);
  
  return (
    <div className="about-edrop__inner">
      <div className="account-box">
        <div className="about-edrop__section">
          <div className="about-edrop__hdr">Allure Bridals</div>
          <div className="about-edrop__p">We craft every one of our wedding tuxedos with high-end fabrics, thoughtful designs, and an eye for class, beauty, and sophistication. Make your big day truly special when you choose Allure Bridals for your wedding suits and tuxedos.</div>
          <div className="about-edrop__p">Our garments are made in a clean, positive work environment using ethically-sourced materials, by people who are passionate about the celebration of beauty and love. Each piece takes an average of 12 to 16 weeks from design to final stitch. We take pride in our production process, because every element is hand-sewn and carefully crafted. Allure’s primary focus is to create with ethically-sourced materials in a positive workspace where creativity thrives. These tuxedos and gowns are individual masterpieces, created by expert craftspeople.</div>
          <div className="about-edrop__p">Allure Bridals : For Your Forever.</div>
          {process.env.CREATOR_ID === '0b10f5cd-ac4c-4a51-99e2-9548bef5d573'
            ? (
              <Link
                to="/claim/?id=a9f72f55-bc94-450e-b785-3e6f09b9b787"
                className="button">
                Unlock Digital Suits & Tuxedos
              </Link>
            )
            : (
              <Link
                to="/claim/"
                className="button">
                Unlock Digital Suits & Tuxedos
              </Link>
            )}
        </div>
        <div className="about-edrop__section">
          <div className="about-edrop__hdr">Allure Men Digital Suits & Tuxedos</div>
          <div className="about-edrop__p">Now presenting Allure Digital Suits & Tuxedos, an online authenticator for your new garment, that lives forever on the blockchain. Download your suit to Apple Wallet, or send to your email for further enjoyment.</div>
          <div className="about-edrop__p">With an Allure Men Digital Suit & Tuxedo, you can always carry a digital keepsake with you that represents the memories you’ve made in this suit. This Digital Suit & Tuxedo also represents Allure Authentication, verifying your garment as an authentic Allure through immutable blockchain technology.</div>
          <div className="about-edrop__p">Allure Suits & Tuxedos: always dapper, even in digital.</div>
        </div>
        
      </div>
      {/* <div className="about-edrop__icons">
        <a href="https://www.instagram.com/heath.skincare/" target="_blank" rel="noreferrer"><img src="https://assets.heyethos.com/heath/instagram-logo-inverse.svg" width="50" alt="Instagram" /></a>
        <a href="https://twitter.com/HeathSkincare" target="_blank" rel="noreferrer"><img src="https://assets.heyethos.com/heath/twitter-logo-inverse.svg" width="50" alt="Twitter" /></a>
      </div> */}
    </div>
  );
}

export default AllureAboutEdrop;
