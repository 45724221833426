import React, { Component } from 'react';

class Gallery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gallery: [],
    };
  }

  createGallery = (creator) => {
    console.log(creator);
    let galleryLength = 180;
    let imagePath = 'https://assets.heyethos.com/legends/gallery2/';

    if (creator.creatorName === 'Klevu') {
      galleryLength = 68;
      imagePath = 'https://assets.heyethos.com/discovered/pdf-images/optimized/';
    }

    let galleryObj = {
      id: 0,
      src: '',
    };
    const galleryArray = [];
    for (let i = 1; i <= galleryLength; i++) {
      galleryObj = {
        id: i,
        src: imagePath + i + '.jpg',
      };
      galleryArray.push(galleryObj);
    }
    this.setState({
      gallery: galleryArray,
    });
  }

  componentDidMount() {
    const { creator } = this.props;
    this.createGallery(creator);
  }

  render() {
    const { gallery } = this.state;
    return (
      <div className="file-viewer__scroller">
        {gallery.length > 0
        && (
        <div className="file-viewer__gallery">
          {gallery.map((item, i) => (
            <img key={item.id} src={item.src} alt="" />
          ))}
        </div>
        )}
      </div>
    );
  }
}

export default Gallery;
