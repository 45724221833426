import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PubSub from 'pubsub-js';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { account } from '@onflow/fcl';
import Throbber from '../throbber';
import AccountManager from '../../managers/Account';
import SimpleModal from '../modal/modal';
import LoginForm from '../account/loginForm';
import BillingForm from '../account/billingForm';
import ConfirmPurchase from './confirmPurchase';
import PaySafeCardEntry from './paySafeCardEntry';
import ConfirmFreebie from './confirmFreebie';
import CreatorManager from '../../managers/Creator';
import { getParameterByName } from '../../managers/Helpers';

class FeaturedDrop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: false,
      isLoading: true,
      isLoadingText: 'Loading membership details. Stand by...',
      accountData: null,
      showModal: false,
      modalMode: '',
      purchaseResult: null,
      creatorId: process.env.CREATOR_ID,
      amountLeft: 0,
      isFree: false,
      hasCard: false,
      paymentToken: null,
      paymentObject: {},
      rawTotal: 0,
      dropData: null,
    };
    PubSub.subscribe('account_updated', this.onPubSubEvent);
  }

  getAccountDetails = async () => {
    const accountData = await AccountManager.get(AccountManager.getToken(), true);
    if (accountData) {
      this.setState({
        accountData,
      });
    }
  }

  onPubSubEvent = async () => {
    await this.getAccountDetails();
    if (AccountManager.isLoggedIn()) {
      this.setState({
        isLoggedIn: true,
      });
    } else {
      this.setState({
        isLoggedIn: false,
      });
    }
  }

  async componentDidMount() {
    const { data, dropId } = this.props;
    console.log('featuredDrop data:');
    console.log(dropId);
    let dropData = data;

    if (dropId) {
      // go get the full drop
      dropData = await CreatorManager.getSingleDropV2(dropId);
    }

    this.setState({
      data: dropData,
      isLoading: false,
    });

    const hasPurchased = localStorage.getItem(process.env.PURCHASE_COOKIE + dropData.drop.dropId);
    if (AccountManager.isLoggedIn()) {
      await this.getAccountDetails();
      this.setState({
        isLoggedIn: true,
        amountLeft: dropData.drop.quantityAvailable,
        isFree: dropData.drop.salesType === 1,
        purchaseResult: hasPurchased ? 'success' : null,
      });
    } else {
      this.setState({
        isLoggedIn: false,
        isFree: dropData.drop.salesType === 1,
        amountLeft: dropData.drop.quantityAvailable,
      });
    }
  }

  componentWillUnmount() {
    toast.dismiss();
  }

  loginModal = (e) => {
    e.preventDefault();
    this.setState({
      showModal: true,
      modalMode: 'login',
    });
  }

  checkBuyStatus = async (e) => {
    const { isFree, accountData } = this.state;
    let mode = '';

    if (isFree) {
      mode = 'freebie';
    } else if (accountData && accountData.billingInfo && AccountManager.getSelectedCard(accountData.paymentMethod)) {
      mode = 'purchase';
    } else if (accountData && accountData.billingInfo && !accountData.paymentMethod) {
      mode = 'card';
    } else {
      mode = 'billing';
    }
    if (e) {
      e.preventDefault();
    }
    this.setState({
      showModal: true,
      modalMode: mode,
    });
  }

  loginSuccess = async () => {
    await this.getAccountDetails();
    this.setState({
      showModal: false,
      isLoggedIn: true,
    });
    toast.success('Log in success.');
    PubSub.publish('account_updated');
    this.checkBuyStatus();
  }

  loginFailed = (error) => {
    toast.error(error || 'Oops! Something went wrong. Try again later.');
  }

  onBillingSuccess = async () => {
    PubSub.publish('account_updated');
    this.setState({
      modalMode: 'card',
    });
  }

  onCardSuccess = async () => {
    const accountData = await AccountManager.get(AccountManager.getToken(), true);
    this.setState({
      accountData,
      modalMode: 'purchase',
    });
  }

  attemptPurchase = async (amount, paymentId, cartToken) => {
    const { data, amountLeft } = this.state;
    const parsedDetails = amount;
    parsedDetails.nftName = data.asset.name;
    parsedDetails.nftLink = data.asset.mediaThumbnailUrl || data.asset.mediaUrl;
    // parsedDetails.assetVariationId = data.asset.assetVariationId;

    // const payload = {
    //   dropId: data.drop.dropId,
    //   amount: amount.rawTotal,
    //   site: window.location.protocol + '//' + window.location.host,
    //   creatorId,
    //   details: parsedDetails,
    // };

    const payload = {
      gasFee: parsedDetails.gasFee,
      dropId: data.drop.dropId,
      nftName: parsedDetails.nftName,
      // assetVariationId: parsedDetails.assetVariationId,
      cartToken: '',
    };

    if (paymentId) {
      payload.pmId = paymentId;
      payload.paymentToken = paymentId;
    }

    if (cartToken) {
      payload.cartToken = cartToken;
    }

    if (getParameterByName('testAmount', window.location.href)) {
      payload.testAmount = parseInt(getParameterByName('testAmount', window.location.href), 10);
    }

    const purchaseData = await CreatorManager.confirmPurchase(payload, AccountManager.getToken());

    if (purchaseData && purchaseData.success) {
      // update the displayed amount remaining
      const newAmountLeft = amountLeft === 0 ? 0 : amountLeft - 1;
      this.setState({
        showModal: false,
        amountLeft: newAmountLeft,
        purchaseResult: 'success',
      });
      gtag('event', process.env.CREATOR_SUBDOMAIN + '_purchase', { // eslint-disable-line
        event_category: process.env.CREATOR_SUBDOMAIN + '_purchase_success',
        event_label: 'User purchased a ' + process.env.CREATOR_SUBDOMAIN + ' NFT',
      });
      toast.success(purchaseData.message, {
        autoClose: false,
      });
      localStorage.setItem(process.env.PURCHASE_COOKIE + data.drop.dropId, 1);
      // PubSub.publish('drop _updated');
    } else {
      this.setState({
        showModal: false,
      });
      toast.error(purchaseData.message || 'Oops! Something went wrong while processing the payment. Please try again later.', {
        autoClose: false,
      });
    }
  }

  updateCardToken = (token) => {
    const { paymentObject } = this.state;
    this.setState({
      paymentToken: token,
    });
    this.attemptPurchase(paymentObject.amount, paymentObject.paymentId, paymentObject.cartToken);
  }

  showCardEntry = (amount, paymentId, cartToken) => {
    const tempPaymentObj = {
      amount,
      paymentId,
      cartToken,
    };
    this.setState({
      rawTotal: tempPaymentObj.amount.rawTotal,
      modalMode: 'cardentry',
      paymentObject: tempPaymentObj,
    });
  }

  closeModal = () => {
    this.setState({
      showModal: false,
    });
  }

  render() {
    const { pageType, layoutStyle } = this.props;
    const {
      isLoading, isLoadingText, data, rawTotal, isFree, isLoggedIn, showModal, modalMode, accountData, purchaseResult, amountLeft,
    } = this.state;
    return (
      <div className={layoutStyle === 'offset' ? 'featured-drop__outer -offset' : 'featured-drop__outer -inverse'}>
        {isLoading
          && <Throbber throbberText={isLoadingText} />}
        <div className={pageType === 'details' ? 'landing-featured-drop -padding' : 'landing-featured-drop'}>
          <SimpleModal isOpen={showModal} onBeforeClose={() => this.setState({ showModal: false })}>
            {modalMode === 'login'
            && <LoginForm onSuccess={() => this.loginSuccess()} onFail={(error) => this.loginFailed(error)} onRegisterButton={() => this.closeModal()} />}
            {(modalMode === 'billing' || modalMode === 'card')
            && (
            <div>
              <BillingForm
                onSuccess={() => this.onBillingSuccess()}
                onCardSuccess={() => this.onCardSuccess()}
                accountInfo={accountData}
                mode={modalMode} />
            </div>
            )}
            {modalMode === 'purchase'
            && (
            <div>
              <ConfirmPurchase
                data={data}
                onPurchaseAttempt={(amount, paymentId, cartToken) => this.attemptPurchase(amount, paymentId, cartToken)}
                purchaseResult={purchaseResult}
                accountInfo={accountData} />
            </div>
            )}
            {modalMode === 'cardentry' && (
              <PaySafeCardEntry
                total={rawTotal}
                onCardEntry={(token) => this.updateCardToken(token)} />
            )}
            {modalMode === 'freebie'
            && (
            <div>
              <ConfirmFreebie
                data={data}
                onPurchaseAttempt={(amount) => this.attemptPurchase(amount)}
                purchaseResult={purchaseResult}
                accountInfo={accountData} />
            </div>
            )}
          </SimpleModal>

          {data
          && (
          <div className="featured-drop__row">
            <div className={layoutStyle === 'offset' ? 'featured-drop__col-image -offset' : 'feature-drop-image__home featured-drop__col-image'}>
              <div className="featured-drop__image">
                {data.asset.mediaMP4 || data.asset.mediaUrl.includes('.mp4')
                  ? (
                    <video autoPlay="autoplay" playsInline loop muted>
                      <source src={data.asset.mediaMP4} type="video/mp4" />
                      <track
                      default
                      kind="captions"
                      srcLang="en"
                      src="" />
                      Your browser does not support the video tag.
                    </video>
                  )
                  : <a href={data.asset.mediaThumbnailUrl || data.asset.mediaUrl} target="_blank" rel="noreferrer"><img src={data.asset.mediaThumbnailUrl || data.asset.mediaUrl} alt="ethos" /></a>}
              </div>
            </div>
            <div className={layoutStyle === 'offset' ? 'featured-drop__col-text -offset' : 'featured-drop__col-text__home featured-drop__col-text'}>
              <div
                className="featured-drop__title"
                dangerouslySetInnerHTML={{ __html: data.asset.name }}>
              </div>
              
              {purchaseResult === 'success'
                ? (
                  <div className="featured-drop__description">
                    <p>
                      Congratulations. Your purchase of
                      {' '}
                      <span
                        dangerouslySetInnerHTML={{ __html: data.asset.name }}>
                      </span>
                      {' '}
                      was successful.
                    </p>
                    
                    <p>Keep an eye on your inbox. An email receipt is on its way.</p>
                    <p>In the meantime, you can view your NFT in your My Account section.</p>
                    <br />
                    <p className="featured-drop__cta"><Link to="/account/collection" className="button">View Your NFT</Link></p>
                  </div>
                )
                : (
                  <div>
                    <div
                  className="featured-drop__description"
                  dangerouslySetInnerHTML={{ __html: data.asset.assetHTMLDesc }}>
                    </div>
                    <div className="featured-drop__description -padding-bottom">{process.env.PURCHASE_INSTRUCTIONS}</div>
                    {!data.asset.name.includes('Onion Haters')
                    && (
                    <div className="featured-drop__volume">
                      {amountLeft}
                      {' '}
                      remain.
                    </div>
                    )}
                    {isFree
                      ? (
                        <div className="featured-drop__amount">
                          $0 USD
                        </div>
                      )
                      : (
                        <div className="featured-drop__amount">
                          $
                          {data.drop.buyItNow}
                          {' '}
                          USD
                        </div>
                      )}
                    {/* <div className="featured-drop__date">Date the drop is available</div> */}
                    {!isLoggedIn
                      ? (
                        <div className="featured-drop__cta">
                          {amountLeft <= 0
                            ? <a className="button-disabled">Sold Out</a>
                            : (
                              <div>
                                <a href="#" className="button" onClick={(e) => this.loginModal(e)}>{isFree ? 'Get it for free' : 'Buy Now'}</a>
                              </div>
                            )}
                        </div>
                      )
                      : (
                        <div>
                          {amountLeft <= 0
                            ? <a className="button-disabled">Sold Out</a>
                            : (
                              <div>
                                <a href="#" className="button" onClick={(e) => this.checkBuyStatus(e)}>{isFree ? 'Get it for free' : 'Buy Now'}</a>
                              </div>
                            )}
                        </div>
                      )}
                  </div>
                )}
              
            </div>
          </div>
          )}
        </div>
      </div>
    );
  }
}

FeaturedDrop.propTypes = {
  data: PropTypes.objectOf(PropTypes.object),
  pageType: PropTypes.string,
  layoutStyle: PropTypes.string,
};

export default FeaturedDrop;
