import React, { useEffect, useState } from 'react';

const BirthDate = ({ onSelectedDate, startingDate }) => {
  // useState hook to manage the birthdate state
  const [birthDate, setBirthDate] = useState('1985-07-03');
  const [userSetBirthDate, setUserSetBirthDate] = useState(null);

  // Event handler for date change
  const handleDateChange = (event) => {
    setBirthDate(event.target.value);
    setUserSetBirthDate(event.target.value);
  };

  useEffect(() => {
    if (userSetBirthDate) {
      onSelectedDate(userSetBirthDate);
    }
  }, [userSetBirthDate]);

  useEffect(() => {
    if (startingDate) {
      setBirthDate(startingDate);
    }
  }, [startingDate]);
  
  return (
    <div>
      <input
        className="input-block"
        type="date"
        id="birthdate"
        value={birthDate}
        onChange={handleDateChange} />
    </div>
  );
};

export default BirthDate;
