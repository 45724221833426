/* eslint-disable react/jsx-props-no-spreading */
import { get } from 'jquery';
import React, { useState, useEffect } from 'react';
import { Link, Outlet, useParams } from 'react-router-dom';
import moment from 'moment-mini';
import AccountManager from '../../managers/Account';
import DigitalAssetTile from './digitalAssetTile';
import Throbber from '../throbber';

function DigitalAssets({ brandId, assets }) {
  const [numAssets, setNumAssets] = useState(0);
  const [digitalAssets, setDigitalAssets] = useState([]);
  const [isDetailsPage, setDetailsPage] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [noAssets, setNoAssets] = useState(false);

  const { nftId } = useParams();

  const getDigitalAssets = async (brandId) => {
    const assets = await AccountManager.getFlatCollection(AccountManager.getToken(), brandId);
    if (assets && assets.length > 0) {
      setNumAssets(assets.length);
      setDigitalAssets(assets);
      setIsLoading(false);
      setNoAssets(false);
    } else if (assets && assets.length === 0) {
      setIsLoading(false);
      setNoAssets(true);
    }
  };

  const getAssetType = (nft) => {
    const assetType = 'image';
    const filename = !nft.nftId ? nft.assetVariation.mediaFileUrl : nft.imageUrl;
    if (filename.includes('.mp4')) {
      return 'video';
    }
    if (filename.includes('.pdf')) {
      return 'pdf';
    }
    return assetType;
  };

  useEffect(() => {
    if (brandId) {
      // getDigitalAssets(brandId);
    }
  }, [brandId]);

  useEffect(() => {
    if (assets && assets.length > 0) {
      setNumAssets(assets.length);
      setDigitalAssets(assets);
      setIsLoading(false);
      setNoAssets(false);
    } else if (assets && assets.length === 0) {
      setIsLoading(false);
      setNoAssets(true);
    }
  }, [assets]);

  useEffect(() => {
    if (nftId) {
      setIsLoading(false);
      setDetailsPage(true);
    }
  }, []);
  return (
    <div className="digital-assets">
      {isDetailsPage
      && <Outlet />}
      {isLoading
        && <Throbber throbberText="Loading membership..." />}
      {noAssets
        ? (
          <div className="digital-assets__header">
            {'You don\'t own any assets from this collection'}
          </div>
        )
        : (
          <div className="digital-assets__header">
            {numAssets}
            {'     '}
            item
            {numAssets !== 1
            && <span>s</span>}
            {' '}
            in this membership
          </div>
        )}
      <div className="digital-assets__group">
        { digitalAssets.length > 0 && (
          digitalAssets.map((item) => (
            <DigitalAssetTile
                key={item.nftId}
                asset={item}
                assetType={getAssetType(item)} />
          ))
        )}
      </div>
    </div>
  );
}

export default DigitalAssets;
