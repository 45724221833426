import React, { useState, useEffect } from 'react';

const Slider = ({
  children, isOpen, onBeforeClose, hideCloseButton,
}) => {
  // const [transferCode, setTransferCode] = useState(null);

  const closeModal = (e) => {
    if (e) {
      e.preventDefault();
    }
    if (onBeforeClose) {
      onBeforeClose(e);
    }
  };

  useEffect(() => {
    // on mount
  }, []);

  return (
    <div>
      <div className={isOpen ? 'slider-container -open' : 'slider-container'}>
        {!hideCloseButton
        && (
        <a className="modal__close" href="#" onClick={(e) => closeModal(e)}>
          <img src="https://assets.heyethos.com/global/icon-close-dark.svg" alt="Close modal" />
        </a>
        )}
        <div className="slider__inner">
          {children}
        </div>
      </div>
      <div className={isOpen ? 'slider-overlay -open' : 'slider-overlay'} onClick={(e) => closeModal(e)}></div>
    </div>
  );
};
export default Slider;
