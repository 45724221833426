import React, { useState, useEffect } from 'react';

const BonLookFlexContent = ({ lang }) => {
  // const [transferCode, setTransferCode] = useState(null);

  const copyStrings = {
    en: {
      heading: 'As a trendsetter, you can access different perks depending on your Trendsetter status.',
      bullet1: 'INFLUENCER (free to join)',
      bullet2: 'MUSE (spend 2 pairs and $250 a year)',
      bullet3: 'ICON (spend 3 pairs and $500 a year)',
      subHeading: 'For more details, see the breakdown of our benefits.',
    },
    fr: {
      heading: 'En tant que membre, vous aurez accès à différents avantages selon votre statut Trendsetter :',
      bullet1: 'INFLUENCER (adhésion gratuite)',
      bullet2: 'MUSE (achat de 2 paires et dépense de 250 $ par année)',
      bullet3: 'ICÔNE (achat de 3 paires et dépense de 500 $ par année)',
      subHeading: 'Pour plus de détails, consultez la répartition des avantages.',
    },
  };

  const scrollToTop = (e) => {
    e.preventDefault();
    // const scrollToElement = document.getElementById(element);
    // scrollToElement.scrollIntoView({ behavior: 'smooth' });
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    // on mount
  }, []);

  return (
    <div className="flex-content -no-margin">
      <div className="flex-content--copy">
        {process.env.SITE_NAME === 'Northie'
          ? (
            <div className="flex-content__inner">
              <div className="flex-content--title">This week only</div>
              <div className="flex-content--description">
                {'Joining the Northie Mountain Loyalty Program this week gives you access to our latest documentary "Northie in Norway". Join now to view it before it\'s removed!'}
              </div>
            </div>
          )
          : (
            <div className="flex-content__inner">
              <p>{copyStrings[lang].heading}</p>
              <div className="flex-content--description">
                <ul>
                  <li>{copyStrings[lang].bullet1}</li>
                  <li>{copyStrings[lang].bullet2}</li>
                  <li>{copyStrings[lang].bullet3}</li>
                </ul>
              </div>
              <p>{copyStrings[lang].subHeading}</p>
            </div>
          )}
      </div>
      <div
        className="flex-content--image"
        style={{
          backgroundImage: 'url(' + process.env.ALTERNATE_BACKGROUND + ')',
        }}>
      </div>
    </div>
  );
};
export default BonLookFlexContent;
